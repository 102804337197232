<template>
  <div :class="$style.DirectionsList">
    <div
      v-for="option in options"
      :key="option[valueName]"
      :class="[
        $style.item,
        { [$style.activeOption]: active === option[labelName] },
      ]"
      @click="onSelected(option)"
    >
      <div :class="$style.left">
        <NuxtImg
          loading="lazy"
          :src="option[imageName] || option.logo.simple"
          :alt="option[labelName]"
        />
        <span>{{ option[labelName] }}</span>
      </div>
      <transition name="fade-fast">
        <span v-if="reserves && reserves.length" :class="$style.gray">{{
          getReserve(option.id)
        }}</span>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import type { Reserve } from "~/types/calculator";

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  labelName: {
    type: String,
    default: "label",
  },
  valueName: {
    type: String,
    default: "value",
  },
  imageName: {
    type: String,
    default: "",
  },
  active: {
    type: String,
    default: "",
  },
  withdraw: {
    type: Boolean,
    default: false,
  },
  reserves: {
    type: Array as PropType<Reserve[]>,
    default: () => [],
  },
});

const emit = defineEmits(["onSelected"]);

function onSelected(item: any) {
  if (props.withdraw) {
    emit("onSelected", item);
  } else {
    if (item[props.labelName] === props.active) return;
    emit("onSelected", item[props.valueName]);
  }
}

function getReserve(id: number) {
  if (!props.reserves) return;
  return props.reserves.find((item) => item.id === id)?.reserve ?? 0;
}
</script>

<style lang="scss" module>
.DirectionsList {
  padding: 2rem 0 0;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: $brand-blue;

  @include respond-to(xs) {
    padding: 15px 0 0;
  }

  &::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $brand-blue;
    border-radius: 5.5rem;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 2rem;
  cursor: pointer;

  &:hover {
    background-color: var(--direction-active-bg);
  }

  &.activeOption {
    background-color: var(--direction-active-bg);
  }

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  img {
    flex-shrink: 0;
    width: 3.2rem;
    height: 3.2rem;
  }

  span {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 150%;

    @include respond-to(xs) {
      font-size: 14px;
    }
  }
}

.gray {
  color: var(--text-color-4);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 130%;

  @include respond-to(xs) {
    font-size: 10px;
  }
}
</style>
